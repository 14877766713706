<template>
  <div>
    <b-row class="mt-3 mb-3">
      <b-col
        cols="12"
        class="title-name"
      >
        {{ $t('views.top-bar.users.create-or-update.general') }}
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col
        cols="6"
        class="field-name"
      >
        {{ $t('views.top-bar.users.create-or-update.select-role') }}
      </b-col>
      <b-col
        class="field-name"
        cols="6"
      >
        <select
          v-model="user.roles[0]"
          class="background-light-blue-inputs form-control"
          size="sm"
          disabled
        >
          <option selected value="ROLE_CLUB_COACH"> {{ $t('views.top-bar.users.create-or-update.coach') }}</option>
          <option value="null"> {{ $t('views.top-bar.users.create-or-update.admin') }}</option>
        </select>
      </b-col>
    </b-row>
    <b-row
      v-if="user.id === null"
      class="mt-2"
    >
      <b-col
        cols="6"
        class="field-name"
      >
        {{ $t('views.top-bar.users.create-or-update.login') }}
      </b-col>
      <b-col
        class="field-name"
        cols="6"
      >
        <validation-provider
          v-slot="{ errors, ariaInput }"
          :rules="validation.username"
        >
          <d-text-field
            v-model="user.username"
            size="sm"
            class-name="background-light-blue-inputs"
          />
          <b-form-invalid-feedback
            v-if="user.username !== ''"
            :state="errors.length === 0"
          >
            {{ $t('validation.text-input-five-characters') }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row
      v-if="user.id === null"
      class="mt-2"
    >
      <b-col
        cols="6"
        class="field-name"
      >
        {{ $t('views.top-bar.users.create-or-update.password') }}
      </b-col>
      <b-col
        class="field-name"
        cols="6"
      >
        <validation-provider
          v-slot="{ errors, ariaInput }"
          :rules="validation.plainPassword"
        >
          <div class="input-group">
            <input
              v-model="user.plainPassword"
              :type="isPasswordType ? 'password' : 'text'"
              size="sm"
              class="form-control form-control-sm background-light-blue-inputs"
            />

            <div class="input-group-append">
            <span
              @click="isPasswordType = !isPasswordType"
              class="input-group-text"
            >
              <i
                :class="isPasswordType ? 'icofont-eye-blocked eye-size' : 'icofont-eye-alt'"
                class="icofont background-light-blue-inputs eye-color"
                aria-hidden="true"
              />
            </span>
            </div>
          </div>
          <b-form-invalid-feedback
            v-if="user.plainPassword !== ''"
            :state="errors.length === 0"
          >
            {{ $t('validation.text-input-eight-characters') }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        cols="6"
        class="field-name"
      >
        {{ $t('views.top-bar.users.create-or-update.account-state') }}
      </b-col>
      <b-col
        v-model="user.enabled"
        class="field-name"
        cols="6"
      >
        <select
          class="background-light-blue-inputs form-control"
          size="sm"
        >
          <option :value="true"> {{ $t('views.top-bar.users.create-or-update.active') }}</option>
          <option :value="false"> {{ $t('views.top-bar.users.create-or-update.not-active') }}</option>
        </select>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-3">
      <b-col
        cols="12"
        class="title-name"
      >
        {{ $t('views.top-bar.users.create-or-update.identity') }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <div class="product-box">
          <div class="product-img">
            <img
              v-if="null !== fileToken"
              :src="fileToken"
              height="135"
              width="135"
              class="object-fit-img img-border"
              alt
            />
            <img
              class="pointer"
              v-else
              height="130"
              width="130"
              src="@/assets/icons/playground/new/step-1/upload-image.svg"
              alt
            />
            <div class="product-hover">
              <ul
                v-if="fileToken === null"
              >
                <li
                  class="pointer"
                  @click="uploadPhoto"
                >
                  <button
                    class="btn"
                    type="button"
                  >
                    <i class="icon-upload"></i>
                  </button>
                </li>
              </ul>
              <ul v-else>
                <li
                  class="pointer"
                  @click="resetPhoto"
                >
                  <button
                    class="btn"
                    type="button"
                  >
                    <i class="icon-trash"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="4">
        <b-row>
          <b-col class="field-name" cols="12">
            {{ $t('views.top-bar.users.create-or-update.firstName') }}
          </b-col>
          <b-col cols="12">
            <d-text-field
              v-model="user.firstName"
              :rules="validation.firstName"
              size="sm"
              error-text="validation.required"
              class-name="background-light-blue-inputs"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col class="field-name" cols="12">
            {{ $t('views.top-bar.users.create-or-update.phone-number') }}
          </b-col>
          <b-col cols="12">
            <d-phone-number
              v-model="user.phoneNumber"
              :rules="validation.phoneNumber"
              size="sm"
              error-text="validation.phoneNumber"
              autocomplete="autocomplete_off"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4">
        <b-row>
          <b-col class="field-name" cols="12">
            {{ $t('views.top-bar.users.create-or-update.lastName') }}
          </b-col>
          <b-col cols="12">
            <d-text-field
              v-model="user.lastName"
              :rules="validation.lastName"
              size="sm"
              error-text="validation.required"
              class-name="background-light-blue-inputs"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col class="field-name" cols="12">
            {{ $t('views.top-bar.users.create-or-update.email') }}
          </b-col>
          <b-col cols="12">
            <d-text-field
              v-model="user.email"
              :rules="validation.email"
              class-name="background-light-blue-inputs"
              size="sm"
              error-text="validation.required"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-4 mb-3">
      <b-col
        cols="12"
        class="title-name"
      >
        {{ $t('views.top-bar.users.create-or-update.presentation') }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-textarea
          size="sm"
          rows="5"
          v-model="user.description"
          class="background-light-blue-inputs"
        >
        </b-textarea>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {_delete} from "@api/doinsport/services/httpService";

export default {
  data: () => ({
    isPasswordType: true,
    fileToken: null,
  }),
  props: {
    user: {
      type: Object,
      default: this,
    },
    validation: {
      type: Object,
      default: this,
    },
  },
  watch: {
    user: function (newUser) {
      this.loadPhoto();
    }
  },
  methods: {
    uploadPhoto() {
      if (this.user.id) {
        this.$upload(this.uploaded, {entry: 'user', url: '/user-clubs/' + this.user.id, target: 'postUserClubAvatar'});
      } else {
        this.$upload(this.uploaded, {entry: 'user', url: null, target: 'postUserClubAvatar'});
      }
    },
    uploaded(file) {
      if (this.user.id === null) {
        const url = URL.createObjectURL(file);

        this.fileToken = url;
        this.user.avatar = file;
      } else {
        this.user.avatar = file;
        this.fileToken = this.$filePath(file.contentUrl);
      }
    },
    resetPhoto() {
      if (this.user.id === null) {
        this.fileToken = null;
        this.user.avatar = null;
      } else {
        _delete(this.user.avatar['@id'])
          .then(() => {
            this.fileToken = null;
            this.user.avatar = null;
          })
        ;
      }
    },
    loadPhoto() {
      if (this.user.id && this.user.avatar) {
        this.fileToken = this.$filePath(this.user.avatar.contentUrl);
      } else {
        this.fileToken = null;
      }
    }
  },
  mounted() {
    this.loadPhoto();
  }
}
</script>
<style scoped lang="scss">
.title-name {
  font: normal normal 900 18px/25px Avenir;
  letter-spacing: 0;
  color: #4D4F5C;
  opacity: 1;
}

.product-box .product-img .product-hover {
  height: 135px;
  width: 135px;
}

.img-border {
  border-radius: 8px;
}

.field-name {
  text-align: left;
  font: normal normal medium 16px/25px Avenir;
  letter-spacing: 0;
  color: #1B1B29;
  opacity: 1;
}

/deep/ .has-calendar .form-control-feedback {
  right: 18px;
  cursor: pointer;
}

/deep/ .has-calendar .form-control {
  padding-left: 0.575rem;
}

/deep/ .input-group-text {
  background-color: #EAF2F7;
  border: 1px solid #ced4da;
  border-left: 0;
  padding-right: 0.3em;
  margin-bottom: 0;
  cursor: pointer;
}

/deep/ .input-group:not(.has-validation) > .form-control:not(:last-child), .input-group:not(.has-validation) > .custom-select:not(:last-child), .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.eye-size {
  font-size: 20px;
  line-height: 0;
  margin-bottom: 1px;
}

.eye-color {
  color: #0c5460;
}
</style>
